import * as PropTypes from "prop-types"
import styled from "styled-components"
import { sendEvent } from "@/scripts/GoogleAnalytics"
import { CallUsButton, DoctoraliaCta, WhatsappCta } from "@/styled/Buttons"
import Typography from "@/styled/Typography"
import { Container, Row } from "@/styled/Grid"
import Col from "@/styled/Grid/Col"

const Background = styled.div`
  background: ${({ theme }) => theme.colors.skyBlue};
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
`

const Buttons = styled(Row)`
  align-items: center;
  a {
    width: 100%;
    &:first-child {
      margin-bottom: 1.5rem;
    }
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    a {
      width: auto;
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
`

export default function AppointmentCta({ pageName, ctaRef, phoneNumber, text, whatsapp, shareText }) {
  const onClickCTA = () => {
    sendEvent("incoming call", { event_category: pageName, event_label: "incoming call appointment" })
  }

  const onClickWhatsappCTA = () => {
    sendEvent("click CTA", { event_category: pageName, event_label: "whatsapp CTA banner" })
  }

  const onClickDoctoraliaCTA = () => {
    sendEvent("doctoralia click", { event_category: pageName, event_label: "doctoralia appointment" })
  }

  return (
    <Background ref={ctaRef}>
      <Container>
        <Typography variant="title3" $txtColor="white" $weight="medium" center>
          {text}
        </Typography>
        <Buttons>
          <Col xs={12} md={6}>
            <CallUsButton onClick={onClickCTA} phoneNumber={phoneNumber} />
          </Col>
          <Col xs={12} md={6}>
            {whatsapp ? (
              <WhatsappCta onClick={onClickWhatsappCTA} shareText={shareText} />
            ) : (
              <DoctoraliaCta onClick={onClickDoctoraliaCTA} />
            )}
          </Col>
        </Buttons>
      </Container>
    </Background>
  )
}

AppointmentCta.defaultProps = {
  ctaRef: null,
  whatsapp: false,
  phoneNumber: "649 321 719",
  text: "Pide tu cita ahora"
}

AppointmentCta.propTypes = {
  whatsapp: PropTypes.bool,
  text: PropTypes.string,
  pageName: PropTypes.string.isRequired,
  ctaRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
}
